import React, { useEffect, useState } from "react";

import Layout from "../../../structure/Layout/Layout";
import Seo from "../../../structure/Seo/Seo";
import Editor from "../../../element/Editor/Editor";
import "./styles.scss";
import { clearLSFilters } from "../../../../utils/Data";
import CitySkyline from "../../../element/Svg/CitySkyline";

const ProjectPage = () => {
  const [path, setPath] = useState("");

  useEffect(() => {
    clearLSFilters();
  }, []);

  return (
    <Layout current='a-propos' subcurrent='le-projet'>
      <Seo title="Le projet | Vu des Quartiers" />
      <div className="page page-grid page-cold page-project" data-theme="primary-25">
        <div className="page_inner">
        <section className="section section-main section-page section-header mini section-description">
            <div className="section_inner radius radius-small radius-bottom radius-left" data-theme="primary">
              <div className="section-title">
                <h1 className="small"><b>Le projet en quelques mots</b></h1>
              </div>
            </div>
            <CitySkyline />
          </section>
          <section className="section section-secondary section-page">
            <div className="section_inner radius radius-small radius-bottom radius-right" data-theme="white">
              <Editor content={`
              <div class="editor-block">
                <p>
                  Un annuaire pour faire émerger vos récits, vos histoires.<br>
                  <u><span>Votre vie au quotidien.</span></u>
                </p>
              </div>
              <div class="editor-paragraph">
                <p>
                  Les journalistes ne peuvent pas agir sans le témoignage des habitants et acteurs des quartiers, qui connaissent mieux que personne ce qui s’y passe.<br>
                  Les quartiers prioritaires sont des territoires définis par l’État pour mieux cibler son action. L’outil couvrira l’ensemble des 1514 quartiers prioritaires de la politique de la ville, sur l’ensemble du territoire national, dans l’Hexagone et en Outremer.<br>
                  « Vu des Quartiers » est un annuaire, qui met en valeur les contacts d’habitants et d’acteurs des quartiers prioritaires qui veulent échanger avec la presse et raconter ce qui se passe chez eux.<br>
                  Les journalistes professionnels peuvent utiliser l’annuaire sur demande et en respectant les règles d’utilisation. Les contacts ne sont accessibles qu’aux journalistes accrédités.
                </p>
              </div>
              <div class="editor-block">
                <p>
                  Ne pas remplacer la voix des quartiers, ne pas se substituer au travail des journalistes
                </p>
              </div>
              <div class="editor-paragraph">
                <p>
                  « Vu des Quartiers » n’est pas un média et ne produit pas de contenus.<br>
                  L’annuaire ne remplace pas le travail des journalistes, qui enquêtent et confrontent les sources. Il vient en complément des recherches des journalistes et au service des habitants et acteurs des quartiers qui s’estiment peu entendus ou mal représentés, et qui veulent témoigner plus justement de leur vie de tous les jours.
                </p>
              </div>
              <div class="editor-block">
                <p>
                  Un objectif commun, au plus près du terrain
                </p>
              </div>
              <div class="editor-paragraph">
                <p>
                  Il ne s’agit pas de dire que tout va mal, ni de donner une image idéalisée ou romancée des quartiers. Il s’agit de parler plus justement et de façon plus équilibrée de ce qui se passe dans les quartiers.<br>
                  Reportages, engagements et initiatives associatives, faits-divers… l’actu des quartiers peut être mieux traitée avec un lien direct entre la presse et les acteurs du quotidien.<br>
                  « Vu des Quartiers » est une initiative cofondée par l’association des maires Ville&Banlieue et BFMTV, et gérée par des rédactions de presse.
                </p>
              </div>
              <div class="editor-paragraph">
                <p>
                  <b>
                    Si vous souhaitez être référencé dans cet annuaire, parler de votre quartier, de vos actions,  <a class="link link-secondary" href="https://vudesquartiers.fr/membre/"><span>inscrivez-vous</span></a> !
                  </b>
                </p>
              </div>
            `} />
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default ProjectPage